<template>
  <div>
    <div>
      <ms-select
        v-model="type"
        label="Search Using"
        :options="accountOptions"
      />
    </div>

    <bank-validator
      v-if="type"
      v-model="deposit_type"
      :v="$v.type"
      class="mt-20"
      :label="selectedAccountOption.text"
      :type="selectedAccountOption.id"
      :action="isRouting ? 'Bank' : 'BankSwift'"
      :show-confirm-button="false"
      @confirm="updateBank($event)"
    />
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

import BankValidator from '@/views/withdrawal/forms/BankValidator'

export default {
  components: {
    BankValidator
  },

  data () {
    return {
      type: null,
      deposit_type: null,

      accountOptions: [
        {
          id: 'routingNumber',
          text: 'Routing Number'
        },
        {
          id: 'swiftCode',
          text: 'SWIFT Code'
        }
      ]
    }
  },

  computed: {
    selectedAccountOption () {
      return this.accountOptions.find(acc => acc.id === this.type)
    },

    isRouting () {
      return this.type === 'routingNumber'
    }
  },

  created () {
    this.$store.commit('page/setPageInfo', {
      title: 'Bank Lookup',
      back: this.previousPageUrl
    })
  },

  validations: {
    type: { required }
  }
}
</script>

<style lang="scss" scoped>
  .button {
    padding-left: 10px !important;
    padding-right: 10px !important;
    font-size: 16px !important;
  }
</style>
